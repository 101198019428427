import browserPlugin from "router5-plugin-browser";
import { routerStore, makeMiddleware } from "mr-router5";
// import appStore from "_/stores";
import authMiddleware from "./middleware";

export default (routes) => {
    const options = {
        defaultRoute: "home",
        strictQueryParams: true,
    };

    const router = routerStore.createRouter(routes, options);

    router.useMiddleware(
        makeMiddleware(authMiddleware),
    );
    router.usePlugin(browserPlugin({ useHash: false, preserveHash: false }));

    return router;
};
