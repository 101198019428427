import { differenceInDays, addDays, addMonths, startOfMonth, startOfDay, getDay, isBefore, nextDay } from "date-fns";

/**
 * Returns the date of the nth weekday of the month.
 * @param {number} dayOfWeek - The day of the week (0-6, where 0 is Sunday).
 * @param {number} nth - The nth occurrence of the day in the month.
 * @param {Date} date - The reference date.
 * @returns {Date} The date of the nth weekday of the month.
 */
const getNthWeekdayOfMonth = (dayOfWeek, nth, date) => {
    // Get the first day of the month
    const firstDayOfMonth = startOfMonth(date);

    // Find first occurrence of the day in the month
    const firstDayOfWeekInMonth = addDays(firstDayOfMonth, (7 + dayOfWeek - getDay(firstDayOfMonth)) % 7);

    // Add (nth - 1) weeks to the first occurrence to get the nth occurrence
    return addDays(firstDayOfWeekInMonth, (nth - 1) * 7);
};

/**
 * Returns the date of the nth weekday of the month.
 * @param {number} dayOfWeek - The day of the week (0-6, where 0 is Sunday).
 * @param {number} weekOfMonth - The week of the month (1-5).
 * @param {Date} currentDate - The reference date.
 * @returns {Date} The date of the nth weekday of the month.
 */
export const getNextMonthlyDate = (dayOfWeek, weekOfMonth, currentDate) => {
    let nextDate = getNthWeekdayOfMonth(dayOfWeek, weekOfMonth, currentDate);

    // Normalize the dates to the start of the day to ignore time
    const normalizedNextDate = startOfDay(nextDate);
    const normalizedCurrentDate = startOfDay(currentDate);

    // If the nextDate is in the past (ignoring time), move to the next month
    if (isBefore(normalizedNextDate, normalizedCurrentDate)) {
        const nextMonth = addMonths(currentDate, 1);
        nextDate = getNthWeekdayOfMonth(dayOfWeek, weekOfMonth, nextMonth);
    }

    return nextDate;
};

/**
 * Returns the next biweekly date based on the start date.
 * @param {Date} startDate - The start date.
 * @param {Date} currentDate - The reference date.
 * @returns {Date} The next biweekly date.
 */
export const getNextBiweeklyDate = (startDate, currentDate) => {
    const biweeklyDays = 14;
    const daysDiff = differenceInDays(currentDate, startDate);
    if (daysDiff % biweeklyDays === 0) {
        return currentDate;
    }
    const weeksDiff = Math.floor(daysDiff / biweeklyDays);
    const nextDate = addDays(startDate, (weeksDiff + 1) * biweeklyDays);
    return nextDate;
};

/**
 * Returns the next weekly date based on the day of the week.
 * @param {Number} dayOfWeek - The day of the week (0-6, where 0 is Sunday).
 * @param {Date} currentDate - The reference date.
 * @returns {Date} The next weekly date.
 */
export const getNextWeeklyDate = (dayOfWeek, currentDate) => {
    if (getDay(currentDate) === dayOfWeek) {
        return currentDate;
    }
    return nextDay(currentDate, dayOfWeek);
};

/**
 * Parse the Google ID token.
 * @param {String} googleIdToken - The Google ID token.
 * @returns {Object} The parsed Google ID token.
 */
export const parseGoogleIdToken = (googleIdToken) => {
    const payload = googleIdToken.split(".")[1];
    const decodedPayload = atob(payload.replace(/-/g, "+").replace(/_/g, "/"));
    return JSON.parse(decodedPayload);
};
