import React from "react";
import { observer } from "mobx-react-lite";

function Prayers() {
    return (
        <h4>Under construction</h4>
    );
}

export default observer(Prayers);
