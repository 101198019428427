import React from "react";
import appStore from "_/stores";
import LinesIcon from "_/components/Layout/Lines";

function About() {
    const { appData } = appStore;

    return (
        <section id="about">
            <div className="container mil-p-120-30">
                <div className="row justify-content-between align-items-center">
                    <div className="col-lg-6 col-xl-5">

                        <div className="mil-mb-90">
                            <h2 className="mil-up mil-mb-60" dangerouslySetInnerHTML={{ __html: appData.about.title }} /> {/* eslint-disable-line */}
                            <div className="mil-text mil-up mil-mb-30" dangerouslySetInnerHTML={{ __html: appData.about.description }} /> {/* eslint-disable-line */}
                        </div>

                    </div>
                    <div className="col-lg-5">

                        <div className="mil-about-photo mil-mb-90">
                            <div className="mil-lines-place">
                                <LinesIcon />
                            </div>
                            {/* <div className="mil-up mil-img-frame" style={{"paddingBottom": "160%"}}> */}
                            <div className="mil-up mil-img-frame" style={{ paddingBottom: 0, width: "100%" }}>
                                {/* <img src={Data.image.url} alt={Data.image.alt} className="mil-scale" data-value-1="1" data-value-2="1.2" /> */}
                                <iframe
                                    width="100%"
                                    height="250"
                                    style={{ border: "none" }}
                                    src={appData.about.video.link}
                                    title={appData.about.video.title}
                                    allowFullScreen
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
