import React from "react";
// import SliderProps from "_/common/sliderProps";
// import { Swiper, SwiperSlide } from "swiper/react";
import appStore from "_/stores";
import "./Activities.css";

function Activities() {
    const { appData } = appStore;
    return (
        <section id="activities" className="activities-container mil-dark-bg">
            <div className="container mil-p-120-30">
                <h2 className="mil-center mil-up mil-mb-60" dangerouslySetInnerHTML={{ __html: appData.activities.title }} /> {/* eslint-disable-line */}
            </div>
            <div className="container videos-container mi-invert-fix">
                {appData.activities.videos.map(({ link, title, description }) => (
                    <div className="video-item" key={title}>
                        <div className="video-link-wrapper">
                            <iframe
                                src={link}
                                title={title}
                                frameBorder="0"
                                className="video-link-wrapper"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                            />
                        </div>
                        <div className="video-description">
                            <h3>{title}</h3>
                            <p>{description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Activities;
