import { RouteView } from "mr-router5";
import Home from "_/components/Home/Home";
import MemberNode from "_/components/RouteNode/MemberNode";
import Login from "_/components/Login/Login";
import Prayers from "_/components/Member/Prayers";
import Notes from "_/components/Member/Notes";
import Events from "_/components/Member/Events";

export default [
    new RouteView({ name: "home", path: "/" }, Home).setExtra("loginRequired", false),
    new RouteView({ name: "login", path: "/login" }, Login).setExtra("loginRequired", false),

    new RouteView({ name: "member", path: "/member" }, MemberNode),
    new RouteView({ name: "member.events", path: "/events" }, Events),
    new RouteView({ name: "member.prayers", path: "/prayers" }, Prayers),
    new RouteView({ name: "member.notes", path: "/notes" }, Notes),
];
