import React from "react";
import appStore from "_/stores";

function Login() {
    const { initializeGoogleSignIn } = appStore.authStore;
    const gsiButtonRef = React.useRef(null);

    React.useEffect(() => {
        initializeGoogleSignIn(gsiButtonRef.current);
    }, []);

    return (
        <section className="mil-banner mil-dark-bg">
            <div className="container member-container">
                <h3>Member Login</h3>
                <p>If you are a registered member of Arising Ages Homeschool Association, please use your Google account to log in.</p>
                <br />
                <br />
                <div ref={gsiButtonRef} className="google-signin-button" />
            </div>
        </section>
    );
}

export default Login;
