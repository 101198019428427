import { makeAutoObservable, action } from "mobx";
import { routerStore } from "mr-router5";
import Swal from "sweetalert2";
import { initializeApp } from "firebase/app";
import { getAuth, signOut, signInWithCredential, GoogleAuthProvider, onAuthStateChanged, setPersistence, browserLocalPersistence } from "firebase/auth";

export default class AuthStore {
    constructor(appStore) {
        this.appStore = appStore;

        this.firebaseAuth = getAuth(this.firebaseApp);

        makeAutoObservable(this, {}, { autoBind: true });
    }

    /**
     * The Firebase user.
     */
    firebaseUser = null;

    /**
     * Initialize the Firebase app.
     */
    firebaseApp = initializeApp({
        apiKey: "AIzaSyDgZUJkJ1rWjdCs0uzjPEq07tKL_dN7vsY",
        authDomain: "arisingages-9c4ed.firebaseapp.com",
        projectId: "arisingages-9c4ed",
        storageBucket: "arisingages-9c4ed.appspot.com",
        messagingSenderId: "697541231373",
        appId: "1:697541231373:web:17db2d2fc9e67ca520a644",
        measurementId: "G-0GL9GHVERL",
    });

    /**
     * Check if the user is authenticated.
     */
    async checkAuth() {
        return new Promise((resolve) => {
            onAuthStateChanged(this.firebaseAuth, action((user) => {
                this.firebaseUser = user;
                resolve(!!user);
            }));
        });
    }

    /**
     * Initialize Google Sign-In.
     */
    initializeGoogleSignIn(gsiButton) {
        const gsiScriptSrc = "https://accounts.google.com/gsi/client";
        if (!document.querySelector(`script[src='${gsiScriptSrc}']`)) {
            const gsiScript = document.createElement("script");
            gsiScript.async = true;
            gsiScript.defer = true;
            gsiScript.src = gsiScriptSrc;
            gsiScript.onload = () => {
                window.google.accounts.id.initialize({
                    client_id: "697541231373-fkgfunul68p7upspcb5b0dphcjhu3nb8.apps.googleusercontent.com",
                    auto_select: true,
                    callback: this.handleGoogleSignIn,
                    use_fedcm_for_prompt: true,
                });
                window.google.accounts.id.renderButton(
                    gsiButton,
                    { theme: "outline", size: "large", text: "continue_with" },
                );
            };
            document.head.appendChild(gsiScript);
        }
    }

    /**
     * Handles Google Sign-In and verifies the Google ID token.
     * @param {String} param.credential - The credential (Google ID Token) from Google Sign-In.
     */
    async handleGoogleSignIn({ credential }) {
        try {
            await this.firebaseSignIn(credential);
            routerStore.router.navigate("member.events");
        }
        catch (err) {
            const { isConfirmed } = await Swal.fire({
                icon: "error",
                title: "Login Error",
                text: "An error occurred while signing in.",
                confirmButtonText: "OK",
            });
            if (isConfirmed) {
                window.location.href = "/";
            }
        }
    }

    /**
     * Sign in with Firebase.
     * As of June 24, 2024, Firebase dropped signInWithRedirect() support for non-Firebase hosting.
     * Since signInWithPopup() isn't great on mobile, I use GSI to get the Google ID token and sign in with Firebase.
     * @param {String} googleIdToken - The Google ID token.
     * @returns {Promise<Object>} - The Firebase user.
     */
    async firebaseSignIn(googleIdToken) {
        const auth = this.firebaseAuth;
        await setPersistence(auth, browserLocalPersistence);
        const credential = GoogleAuthProvider.credential(googleIdToken);
        const { user } = await signInWithCredential(auth, credential);
        return user;
    }

    /**
     * Sign out the user.
     */
    async firebaseSignOut() {
        try {
            await signOut(this.firebaseAuth);
        }
        finally {
            window.location.href = "/";
        }
    }
}
