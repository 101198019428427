import React from "react";
import { observer } from "mobx-react-lite";
import appStore from "_/stores";
import determinationPoster from "_/assets/files/Determination-Poster-9_15_2024.pdf";

function Notes() {
    const [notesData, setNotesData] = React.useState(null);

    React.useEffect(() => {
        (async () => {
            const data = await appStore.dataStore.fetchNotes();
            setNotesData(data);
        })();
    }, []);

    if (!notesData) {
        return null;
    }

    return notesData.map(({ content }) => (
        <div className="block" key={content} dangerouslySetInnerHTML={{ __html: content }} />
    ));
}

export default observer(Notes);
