import React from "react";
import { observer } from "mobx-react-lite";
import { RouteNodeComponent, routerStore } from "mr-router5";
import "./MemberNode.css";

const routeName = "member";

function MemberNode() {
    const { router, route } = routerStore;

    const menuItems = [
        ["Prayers", "member.prayers"],
        ["Events", "member.events"],
        ["Character", "member.notes"],
    ];

    return (
        <section className="mil-dark-bg">
            <div className="container member-container">
                <div className="nav-menu">
                    {menuItems.map(([menuLabel, menuRouteName]) => (
                        <div
                            key={menuRouteName}
                            className={`nav-item ${route.name === menuRouteName ? "active" : ""}`}
                            onClick={() => router.navigate(menuRouteName)}
                            onKeyDown={() => router.navigate(menuRouteName)}
                            role="button"
                            tabIndex={0}
                        >
                            {menuLabel}
                        </div>
                    ))}
                </div>
                <RouteNodeComponent routeNodeName={routeName} />
            </div>
            <hr />
        </section>
    );
}

export default observer(MemberNode);
