import appStore from "_/stores";

export default async function authMiddleware({ done, getExtra }) {
    const loginRequired = getExtra("loginRequired", true);
    if (loginRequired) {
        const isAuth = await appStore.authStore.checkAuth();
        if (isAuth) {
            done();
        }
        else {
            window.location.href = "/login";
        }
    }
    else {
        done();
    }
}
