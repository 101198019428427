import { makeAutoObservable } from "mobx";
import getLanguage from "_/get-lang";
import DataStore from "./DataStore";
import AuthStore from "./AuthStore";

export default class AppStore {
    constructor() {
        this.authStore = new AuthStore(this);
        this.dataStore = new DataStore(this);

        makeAutoObservable(this, {}, { autoBind: true });
    }

    /**
     * The current language code.
     */
    language = "";

    /**
     * The app data for the current language
     */
    appData = {};

    /**
     * Set the language for the app and load the app data.
     * @param {String} lang - The language code.
     */
    async setLanguage(lang) {
        this.language = getLanguage(lang);
        const appData = await this.loadDataFile("app");
        Object.assign(this.appData, appData);
    }

    /**
     * Load the data file.
     * @param {String} dataType - The data type.
     * @returns {Promise<Object>} - The data object.
     */
    async loadDataFile(dataType) {
        try {
            const data = await import(`_/data/${dataType}-${this.language}.json`);
            return data;
        }
        catch (err) {
            throw new Error("Error loading language data:");
        }
    }
}
