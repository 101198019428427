import React from "react";
import { createRoot } from "react-dom/client";
import createRouter from "_/routing/create-router";
import routeViews from "_/routing/routes";
import MainNode from "_/components/RouteNode/MainNode";
import appStore from "_/stores";
import "_/assets/styles/plugins/bootstrap-grid.css";
import "_/assets/styles/plugins/font-awesome.min.css";
// import "_/assets/styles/plugins/magnific-popup.css";
// import "_/assets/styles/plugins/swiper.min.css";
import "_/assets/styles/scss/style.scss";
import "_/assets/styles/globals.css";

// router
const router = createRouter(routeViews);

// create root element
const root = createRoot(document.getElementById("app"));

// renderer
const renderApp = async () => {
    await appStore.setLanguage();
    document.title = appStore.appData.siteTitle;
    root.render(<MainNode />);
};

export {
    renderApp,
    router,
};
