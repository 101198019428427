import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import { fromUnixTime, setHours, setMinutes, setSeconds, startOfDay, isBefore } from "date-fns";
import { getNextMonthlyDate, getNextBiweeklyDate } from "_/utils";
import Swal from "sweetalert2";

export default class DataStore {
    constructor(appStore) {
        this.appStore = appStore;

        this.db = getFirestore(this.appStore.authStore.firebaseApp);
    }

    /**
     * Get the documents data from a collection.
     * @param {String} collectionName - The collection name.
     * @param  {...any} queryArgs - The query arguments.
     * @returns {Promise<Array>} The documents data
     */
    /* eslint-disable consistent-return */
    async getDocsData(collectionName, ...queryArgs) {
        try {
            const collectionRef = collection(this.db, collectionName);
            const q = query(collectionRef, ...queryArgs);
            const querySnapshot = await getDocs(q);
            return querySnapshot.docs.map((doc) => doc.data());
        }
        catch (error) {
            let title = "Data Retrieval Error";
            let text = "An error occurred while retrieving the data.";

            if (error.code === "permission-denied") {
                title = "Member Access Only";
                text = "Only Arising Ages members can access this content.";
            }

            const { isConfirmed } = await Swal.fire({
                icon: "error",
                title,
                text,
                confirmButtonText: "OK",
            });
            if (isConfirmed) {
                this.appStore.authStore.firebaseSignOut();
            }
        }
    }

    /**
     * Retrieve the upcoming events data.
     * @returns {Promise<Array>} The upcoming events data.
     */
    async getUpcomingEvents() {
        const eventsData = await this.getDocsData("events");
        const upcomingEvents = [];
        const currentDate = new Date();
        eventsData.forEach((event) => {
            const { recurrence } = event;
            let normalizedDates = null;
            if (recurrence) {
                let nextDate = null;
                if (recurrence.type === "biweekly") {
                    nextDate = getNextBiweeklyDate(fromUnixTime(recurrence.dates[0].seconds), currentDate);
                }
                else if (recurrence.type === "monthly") {
                    nextDate = getNextMonthlyDate(recurrence.dayOfWeek, recurrence.weekOfMonth, currentDate);
                }
                if (nextDate) {
                    normalizedDates = recurrence.dates.map(({ seconds }) => {
                        const date = fromUnixTime(seconds);
                        nextDate = setHours(nextDate, date.getHours());
                        nextDate = setMinutes(nextDate, date.getMinutes());
                        nextDate = setSeconds(nextDate, date.getSeconds());
                        return nextDate;
                    });
                }
            }
            else {
                const lastDate = fromUnixTime(event.dates[1]?.seconds ?? event.dates[0].seconds);
                if (!isBefore(startOfDay(lastDate), startOfDay(currentDate))) {
                    normalizedDates = event.dates.map(({ seconds }) => fromUnixTime(seconds));
                }
            }
            if (normalizedDates) {
                upcomingEvents.push({ ...event, dates: normalizedDates });
            }
        });
        upcomingEvents.sort((a, b) => a.dates[0] - b.dates[0]);
        return upcomingEvents;
    }

    /**
     * Fetch the notes data.
     * @returns {Promise<Array>} The notes data.
     */
    async fetchNotes() {
        return this.getDocsData("notes", where("isActive", "==", true));
    }
}
