if (GLOBAL.GTAG_ID) {
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GLOBAL.GTAG_ID}`;
    script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        const gtag = (...args) => window.dataLayer.push(args);
        gtag("js", new Date());
        gtag("config", GLOBAL.GTAG_ID);
    };
    document.head.appendChild(script);
}
