import React from "react";
import { observer } from "mobx-react-lite";
import { format, isSameDay } from "date-fns";
import appStore from "_/stores";

function Events() {
    const [eventsData, setEventsData] = React.useState(null);

    React.useEffect(() => {
        (async () => {
            const data = await appStore.dataStore.getUpcomingEvents();
            setEventsData(data);
        })();
    }, []);

    if (!eventsData) {
        return null;
    }

    // date formatting
    const dateFormat = "MM/dd/yyyy EE";
    const timeFormat = "hh:mm a";
    const dateTimeFormat = `${dateFormat} ${timeFormat}`;
    const formatDate = (date) => format(date, date.getHours() ? dateTimeFormat : dateFormat);
    const getDateLabel = ([fromDate, toDate]) => {
        let dateLabel = formatDate(fromDate);
        if (toDate) {
            if (!isSameDay(fromDate, toDate)) {
                dateLabel += ` - ${formatDate(toDate)}`;
            }
            else if (toDate.getHours()) {
                dateLabel += ` - ${format(toDate, timeFormat)}`;
            }
        }
        return dateLabel;
    };

    // render upcoming events
    return eventsData.map((event) => {
        const dateLabel = getDateLabel(event.dates);

        return (
            <div key={`${event.title}${dateLabel}`} className="block event-block">
                <div className="block-title">
                    {event.title}
                    {event.url && (
                        <>
                            <span className="spacer spacer-tiny" />
                            <a href={event.url} target="_blank" rel="noreferrer"><span className="fa fa-link" /></a>
                        </>
                    )}
                    {event.mapUrl && (
                        <>
                            <span className="spacer spacer-tiny" />
                            <a href={event.mapUrl} target="_blank" rel="noreferrer"><span className="fa fa-location-dot" /></a>
                        </>
                    )}
                </div>
                <p className="event-date">{dateLabel}</p>
                {event.notes && (
                    <div className="event-notes">
                        {event.notes.map((line) => <p key={line}>{line}</p>)}
                    </div>
                )}
            </div>
        );
    });
}

export default observer(Events);
