/**
 * List of available languages supported by the application.
 * @constant {string[]}
 */
const availableLanguages = ["en", "zh"];

/**
 * Default language to use if no language is stored or detected.
 */
const defaultLanguage = "zh";

/**
 * Checks if localStorage is available and accessible in the current environment.
 * It tries to store and remove a test key in localStorage to ensure functionality.
 *
 * @returns {boolean} Returns true if localStorage is available, false otherwise.
 */
function isLocalStorageAvailable() {
    try {
        const testKey = "__storage_test__";
        localStorage.setItem(testKey, testKey);
        localStorage.removeItem(testKey);
        return true;
    }
    catch (e) {
        return false;
    }
}

/**
 * Determines the appropriate language to use for the user.
 * - If `langCode` is provided, it validates the code, stores it in localStorage, and returns it.
 * - If `langCode` is not provided, it checks localStorage for a previously stored language.
 * - If no language is stored, it detects the browser language and stores it in localStorage.
 * - Defaults to 'en' if localStorage is unavailable or the detected language is unsupported.
 *
 * @param {string} [lang] - Optional. The language code to set (e.g., "en", "zh").
 * @returns {string} The valid language code based on localStorage, browser detection, or default.
 * @throws {Error} Throws an error if the provided language code is invalid.
 */
export default function getLanguage(lang) {
    // if localStorage is not available, return 'en' as the default language
    if (!isLocalStorageAvailable()) {
        console.warn("localStorage is not available. Language preference cannot be stored."); // eslint-disable-line no-console
        return defaultLanguage;
    }

    // key to store the preferred language in localStorage
    const localStorageKey = "preferredLanguage";

    // if language is provided, validate it and store it in localStorage
    if (lang) {
        if (!availableLanguages.includes(lang)) {
            throw new Error(`Invalid language code: ${lang}`);
        }
        localStorage.setItem(localStorageKey, lang);
        return lang;
    }

    // if language is not provided, get the language from localStorage
    let storedLanguage = localStorage.getItem(localStorageKey);

    // if no language is stored in localStorage, get the browser language and store it in localStorage
    if (!storedLanguage) {
        const browserLang = navigator.language || navigator.userLanguage;
        const detectedLang = browserLang.split("-")[0];
        storedLanguage = availableLanguages.includes(detectedLang) ? detectedLang : defaultLanguage;
        localStorage.setItem(localStorageKey, storedLanguage);
    }
    return storedLanguage;
}
